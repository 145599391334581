.login-content {
   width: 350px;
   height: 150px;
   border:  1px solid #eee;
   border-radius: 10px;

}

.input-password {
    height: 40px;
    width: 345px;
    border-radius: 6px;
    padding-left: 10px;
}