.conspicuous-number{
    font-size: 40px;
    padding-left: 15px;
    padding-top: 5px;
    display: flex;
    align-items:baseline;

}

.conspicuous-number-value {
    font-size: 40px;
    color: rgb(5, 211, 128);
    font-weight: bold;
}
.conspicuous-number-unit {
    font-size: 18px;
    margin-left: 15px;
}