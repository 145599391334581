.bar-value {
  color: #f8f8f8;
  font-size: 20px;
}

.region-container {
  height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before {
    content: "";
    background: rgba(65, 61, 61, 1);
    height: 1px;
    position: absolute;
    width: 50px;
    left: 50px;
  }

  &::after {
    content: "";
    background: rgba(65, 61, 61, 1);
    height: 1px;
    position: absolute;
    width: 50px;
    right: 50px;
  }
}

.card-title {
  margin-left: 8px;
}

.time-select {
  color: #4992ff;
  font-weight: bold;
}

.page-bar-content {
  display: flex;
  justify-content: space-between;
  width: 375px;
  position: fixed;
  padding: 10px;
  background: rgb(0, 0, 0);
  z-index: 999;
}
.page-bar {
  height: 40px;
}

.am-tabs-default-bar-top .am-tabs-default-bar-prevpage,
.am-tabs-default-bar-top .am-tabs-default-bar-nextpage {
  display: none;
}

.date-input-label {
  font-size: 16px;
  margin-right: 20px;
}

.date-input-content {
  font-size: 16px;
  height: 20px;
  width: 200px;
  border-bottom: 1px solid #3e3e3e;
}

.time-bar-datepicker-content {
  padding-left: 40px;
  .close-btn {
    height: 30px;
    width: 30px;
    position: absolute;
    right: 20px;
    top:85px;
    background-image:url("../../assets/images/close.png");
    background-size: cover;
    

  }

}
