.datepicker-content {
    position: fixed;
    display: flex;
    justify-content: center;
    z-index: 999;
    width: 375px;
    height: 1000px;
    background: #7f7d7d7b;
    top: 0;
    left: 0;
}

.datepicker-container {
    width: 300px;
    height: 435px;
    background: white;
    margin-top: 130px;
    border-radius: 10px;

}

.datepicker-head {
    padding: 0 10px 0 10px;
    width: 300px;
    height: 100px;
    background: rgb(20, 21, 43);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius:10px ;
    border-top-right-radius:10px ;
    font-size: 28px;
    font-weight: bold;
    
    .left-btn {
        height: 40px;
        width: 40px;
        background-image: url('./arrow2.png');
        background-size:contain;
        opacity: 0.8;
    }
    .right-btn {
        height: 40px;
        width: 40px;
        background-image: url('./arrow2.png');
        background-size:contain;
        transform:rotate(180deg);
        opacity: 0.8;

    }
    .current-month {
        width: 100px;
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 60px;
        margin-top: 30px;

    }
    .year {
        font-size: 16px;
        margin-top: 15px;
        opacity: 0.6;
    }




}

.datepicker-body {
    .week {
        padding: 0 15px 0 15px;
        display: flex;
        height: 30px;
        align-items: center;
        border-bottom: 1px solid #eee;
        color: #333;
        font-size: 16px;
        justify-content: space-between;
        margin-bottom: 5px;

        li {
            text-align: center;
            width: 30px;
            font-weight: bold;
        }
    }

    .date {
        padding: 0 15px 0 15px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #eee;
        li{
            height: 30px;
        }

        .date-selected {
            background: rgb(240, 152, 19);
            color: #fff;
            border-radius: 50px;
        }

        .date-default {
            background: rgb(14, 171, 228);
            color: #fff;
            border-radius: 50px;
        }
        .date-disable {
            background: #aeadad;
            border-radius: 50px;
            opacity: 0.8;
            color: #fff;

        }

        li {
            width: 30px;
            text-align: center;
            margin-right: 10px;
            line-height: 30px;
            color: #333;
            font-size: 16px;
            color: #333;
            margin-bottom: 10px;
        }


        li:nth-child(7n) {
            margin-right: 0px;

        }

    }
}

.datepicker-foot {
    margin-top: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: space-between;
    .year {
        color: #333;
        padding-left: 20px;
        font-size: 16px;
        opacity: 0.8;
        line-height: 30px;
    }
    .btns {
        display: flex;
    }


}

.date-select-content {
    display: flex;
}

.date-label {
    font-size: 15px;
    margin-right: 15px;
    line-height: 30px;
}

.date-input {
    font-size: 15px;
    border-bottom: 1px solid #8383837d;
    line-height: 30px;
    width: 200px;

}