//TODO:自适应高度
.loading{
  width: 100%;
  height: 700px;
  display: flex;

}
.spinner {
  margin: 300px auto 0 auto;
  width: 70px;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: rgb(21, 125, 190);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.loading-2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}



@keyframes rotate{
  0%{
      transform: rotate(0);
    }

  100%{
       transform: rotate(360deg);
    }
}

.rotate{
  animation: rotate 1s linear infinite;  /*开始动画后无限循环，用来控制rotate*/
}